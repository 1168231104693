/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import { Translate } from "components/i18n/index.js";
import { Link } from "react-router-dom";
import { ContactUs } from "components/ContactUs";

import './style.scss';

class Terms extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <main ref="main">
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default" />
          <Container>
            <Row className="justify-content-center text-center mb-sm">
              <Col lg="8">
                <h2 className="display-3"><Translate>Terms</Translate></h2>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <Row>
              <Col sm="12">
                <p>This Customer Compliance sets forth the terms and conditions for the use of the Alborz Payment services (hereinafter referred to as the&nbsp;<strong>&ldquo;Services&rdquo;</strong>) provided by Alborz Payment Canada Inc. (hereinafter referred to as the&nbsp;<strong>&ldquo;Alborz Payment&rdquo;</strong>,&nbsp;<strong>"we,"</strong>&nbsp;<strong>&ldquo;us,&rdquo;</strong>&nbsp;and&nbsp;<strong>&ldquo;our&rdquo;</strong>).</p>
                <p>By registering or using an Alborz Payment Account, or by otherwise using the Services, Customers (hereinafter referred to as&nbsp;<strong>&ldquo;you&rdquo;</strong>&nbsp;and&nbsp;<strong>&ldquo;your&rdquo;</strong>,<strong> &ldquo;user&rdquo;</strong>) accept to be bound by this Customer Compliance and consent to receive communications related to the Services or your Alborz Payment Account. Customers are kindly asked to read the terms and conditions set forth in this Customer Compliance when registering to use the Services. Alborz Payment recommends that you print out a copy of this Customer Compliance to retain as a reference. You will also need to carefully read and accept our&nbsp;<strong>Privacy Policy</strong>, which sets forth the terms on which we handle any personal data we collect about you, or that you provide to us. Please note that if you do not accept this Customer Compliance and Privacy Policy, you will not be allowed to use the Services. By using our Services, you consent to such processing and you confirm that all data provided by you is accurate.</p>
                <p>Please read carefully all of the terms of these policies that apply to you. Your use of certain of the Services may be subject to additional terms and conditions, as communicated by us to you through the Service, and such additional terms and conditions are incorporated into this Customer Compliance. This Customer Compliance contains several sections, and you should read all of them in detail.</p>
                <ul>
                  <li><strong> Alborz Payment and its Affiliate</strong></li>
                </ul>
                <p>Alborz Payment is registered in Canada with the Financial Transactions Reports Analysis Centre of Canada (<strong>&ldquo;FINTRAC&rdquo;</strong>), registration number M20486691. Alborz Payment is registered as a money service business and is able to provide foreign exchange dealings and money transfer services to customers resident in Canada. Alborz Payment does not provide other services and is not a payment processor, bank, or other financial institution. Moreover, Alborz Payment does not accept cash or cryptocurrency under any circumstance.&nbsp;</p>
                <ul>
                  <li><strong> Eligibility</strong></li>
                </ul>
                <p>Alborz Payment customers must be at least 18 years old, or the age of majority in their province or territory of residence. If you are an individual, you further guarantee us that your actions are for your own interest and do not involve any third party principal. In Alborz Payment website transactions on behalf of any third party are strictly prohibited. Alborz Payment does not tolerate an account being used by anyone other than the registered customer. Also, customers are not allowed to share their login credentials with anyone. Any suspicious activity results in immediate suspension of the account.</p>
                <p>&nbsp;</p>
                <p>You may not use Alborz Payment services for activities that:&nbsp;</p>
                <ul>
                  <li>violate any law, statute, ordinance or regulation.</li>
                  <li>relate to transactions involving (a) any entity that is subject to asset freezes, or sanctioned. (b) sanctioned and blocked high tech products and telecommunications equipment. (c) uranium enrichment, nuclear development work and ballistic missile programs. (d) certain weapons or knives regulated under applicable law. (e) items that are considered obscene (f) <em>narcotics, steroids, certain controlled substances or other products that present a risk to consumer safety</em>, (2) drug paraphernalia and cigarette</li>
                  <li>involve the sales of products or services identified by government agencies to have a high likelihood of being fraudulent.</li>
                </ul>
                <ul>
                  <li><strong> The Services</strong></li>
                </ul>
                <ul>
                  <li><strong>Opening an Alborz Payment Account</strong></li>
                </ul>
                <p>To use our Services, you first need to open an Alborz Payment Account by providing some basic essential information. Legally, you must provide all the information accurately, complete and totally based on truth during the registration process or any time afterwards. You are responsible to update any personal information in your Alborz Payment Account profile such as your mailing address, telephone number, email address, and other contact information through your personal profile page after logging into Alborz Payment Website. We may avoid or discontinue providing the Services to any person or entity for any reason at any time. All activities of a registered user would be treated under a personalized created Alborz Payment Account.</p>
                <ul>
                  <ul>
                    <li><strong>Account Security and Privacy</strong></li>
                  </ul>
                </ul>
                <p>Protecting your privacy is our first priority. Alborz Payment servers store users' passwords in an encrypted format. We do not disclose your personal information, including account details, postal or email addresses to anyone except when legally required to do so and as specified in our Privacy Policy. Alborz payment is using Secure Socket Layer (SSL) to transfer your sensitive information between your browser and the alborzpayment.com/alborzpayment.ca website (the&nbsp;<strong>&ldquo;Alborz Payment Website&rdquo;</strong>). You need to always make sure that your browser can validate the Alborz Payment.com certificate before transmitting sensitive information to Alborz Payment Website. For more details on our Privacy Policy, please click here&nbsp;<Link to="/privacy"><strong>https://alborzpayment.com/#/privacy</strong></Link>.</p>
                <p>You, not Alborz Payment, are responsible for keeping safe and securing your profile authentication such as all IDs, passwords, or any other details you use to access your Alborz Payment Account from any other person or entity. You must be cautious and be careful to not disclose your Alborz Payment Account password, any of your customer reference numbers, or any discount code provided to you for using only for your own use of Alborz Payment Services. We will never ask you to provide your password to us or to a third party via email, text, call, mail, or any other possible ways. You need to inform us in case anyone asks you for your password, or any other security-related matter of your Alborz Payment Account and immediately contact Customer Support of Alborz Payment if you are not sure about this. Your Alborz Payment Account must only be used by you and you must never let anyone access your Alborz Payment Account in any way. For security reasons, you also must never let anyone other than yourself to watch when you attempt to access your Alborz Payment Account.</p>
                <p>You are advised to change your password any time that you suspect your password or login details are lost, stolen or used by anyone without authorization. You have to contact Customer Support immediately if you suspect your password or login details have been compromised or you are doubtful about the security and safety of your password or any security matter. The disclosure of your login details and credentials could enable thieves to access your bank account and attempt transactions not authorized by you. In case of emergency, telephoning us is the best and fastest way to minimize your risk of loss and solving any issue related to the security of your account. Further, if you see any transaction in the history of your Alborz Payment Account that you did not recognize initiating by you, contact us immediately. You can reach our Customer Support at <a href="tel:+1 (647) 745-4666">+1 (647) 745-4666</a>.</p>
                <p>We strongly recommend you to check the transactions history of your Alborz Payment Account regularly and contact Customer Support immediately in case you have any concerns or questions. We may (but are not obligated to) restrict the functionality of your Alborz Payment Account or even suspend it if we have any security or safety related concerns about it or any of its security features; or potential unauthorized, suspicious or fraudulent use of your Alborz Payment Account.&nbsp;</p>
                <p>As your email address is what you need to reset your passwords and to communicate with you about the safety and security of your Alborz Payment Account. In case your email address becomes compromised, let Customer Support know immediately. Never allow your computer or browser to store, cache, or record your login details or passwords in any way. We notify you to familiarize yourself with additional security requirements that you might need in order to use additional Alborz Payment products or Services.</p>
                <ul>
                  <ul>
                    <li><strong>Duplicate Accounts</strong></li>
                  </ul>
                </ul>
                <p>Due to security and user identification requirements, Alborz Payment holds the right to refuse the creation of any duplicate accounts for the same user. Accordingly, if Alborz Payment recognizes any duplicate accounts, it has the right to merge or close the duplicate accounts without any prior notification or message to you.</p>
                <ul>
                  <ul>
                    <li><strong>Verification</strong></li>
                  </ul>
                </ul>
                <p>When you open an Alborz Payment Account and use the Services, based on law we are required to validate your information. You give Alborz Payment permission to make any inquiries we consider essential to verify your identity, which can be either directly or by third parties. For that we may ask for bank account ownership verification. We also request a certified photo ID. This is sufficient if bank account ownership is also verified.</p>
                <p>Alborz Payment reserves its right to access any databases in order to verify your information. Alborz Payment reserves its right to suspend, terminate, or limit access to your Alborz Payment Account and/or the Services in the case we are not able to retain or verify this information.</p>
                <ul>
                  <li><strong>Depositing Money</strong></li>
                </ul>
                <p>You may deposit funds into your Alborz Payment Account in order to send it to another person. Note that Users are not able and not allowed to hold a significant balance on their Alborz Payment accounts at this moment.&nbsp;</p>
                <p>You can deposit money through Interac e-transfer or any available payment gateway on Alborz Payment website in one or more currencies. Alborz Payment does not guarantee the use of any particular deposit method and might change or terminate offering a particular deposit method at any time without notice. Alborz Payment might add more deposit methods in future.</p>
                <p>Alborz Payment will credit your Account balance right after receiving funds from your bank or credit card issuer.&nbsp;</p>
                <ul>
                  <li><strong>Funding with A Bank Account</strong></li>
                </ul>
                <p>Funding your Alborz Payment Account using Interac e-transfer requires you to transfer money electronically through your bank account via Interac e-transfer services using your email address or Canadian phone number. If you fund your account using Interac e-transfer and provide necessary details, you confirm that these details are correct, that you are authorized to access and use the bank account, that you have sufficient funds to complete the deposit and that you have the authority to initiate an Interac e-transfer from your bank account.&nbsp;</p>
                <ul>
                  <li><strong>Funding through any gateway provided on Alborz Payment</strong></li>
                </ul>
                <p>Funding your Alborz Payment Account using a payment gateway(s) available on Alborz Payment website in one or more currencies requires you to transfer money electronically through the payment gateway(s). Funding your Alborz Payment Account using a payment gateway needs you to start and complete the transaction all through the gateway. After that the funds are confirmed to be available in the account of Alborz Payment, they will be credited to your Alborz Payment Account.</p>
                <p>&nbsp;</p>
                <ul>
                  <li><strong>Holding A Balance in your Alborz Payment Account</strong></li>
                </ul>
                <p>&nbsp;</p>
                <ul>
                  <ul>
                    <li><strong>Alborz Payment is not a bank and subsequently your Alborz Payment Account is not a bank account.</strong></li>
                  </ul>
                </ul>
                <p>Alborz Payment is not a bank whatsoever, and subsequently, any value in the balance of your Alborz Payment Account is an unsecured claim against Alborz Payment and is not insured by the Canada Deposit Insurance Corporation (CDIC) or any other deposit protection scheme. Users are required to transfer or withdraw the most part of deposited funds within 24 hours of their receipt in the Alborz Payment system. Users should not hold any fund in their balance more than $10.00 CAD, or equivalent at any time at all. Neither Alborz Payment nor funds held within Alborz Payment system, is not insured by the CDIC or any other deposit insurance scheme. On that note, clients are not entitled to receive any interest or other claims/gains on any balance held in the Alborz Payment system.</p>
                <ul>
                  <ul>
                    <li><strong>Transactions History</strong></li>
                  </ul>
                </ul>
                <p>In case you need to have access to the history of your transactions, including your current balance, deposited money, and money you have received, converted, sent and/or withdrawn, related fees and exchange rates, if applicable, they are recorded in the transaction history section of your Alborz Payment Account.</p>
                <p>To access the aforementioned information, you have to log into your Alborz Payment Account. Each transaction is identified by a transaction number; you need this transaction number when sending any request to us regarding a particular transaction.</p>
                <ul>
                  <ul>
                    <li><strong>Foreign Exchange</strong></li>
                  </ul>
                </ul>
                <p>You might hold your balance in multiple currencies. You, not Alborz Payment, are responsible for any volatility, market change, or similar risk caused by holding a balance in multiple currencies. In addition, you must not not use the Alborz Payment system for any currency arbitrage or speculation purposes.</p>
                <ul>
                  <li><strong>Sending or Withdrawing Money</strong></li>
                </ul>
                <p>You may use you available balance in the following ways:&nbsp;</p>
                <ul>
                  <li>Transferring the balance to your own bank account</li>
                  <li>Transferring a remittance to another Alborz Payment account</li>
                  <li>Closing your account and requesting for a payment equal to your available balance</li>
                </ul>
                <p>Not all users have all withdrawal/transfer methods available in their accounts, so they need to check their current account status to be sure about. Alborz Payment does not guarantee the availability and use of any particular withdrawal/transfer method and may change, limit, or stop offering a particular withdrawal/transfer method at any time without any prior notice.</p>
                <p>Fees are charged for all withdrawal and remittance transactions; the exact fee is shown up front on our webpage at the time of submission of your request. Fees include transaction fees, foreign exchange rate, and other fees. &nbsp;</p>
                <ul>
                  <ul>
                    <li><strong>Request Limits&nbsp;</strong></li>
                  </ul>
                </ul>
                <p>As your Account is subject to deposit, withdrawal and send money limits, Alborz Payment declines your request in case it exceeds your current limit.&nbsp;</p>
                <ul>
                  <ul>
                    <li><strong>Clearing Times</strong></li>
                  </ul>
                </ul>
                <p>There are clearing times associated with each transaction that totally depend on the banks which process them and industry timelines. Subsequently, Alborz Payment does NOT have any control over processing timelines in the banks working to process payments. &nbsp;</p>
                <p>&nbsp;</p>
                <ul>
                  <li><strong>Foreign </strong><strong>Currency Conversion</strong></li>
                </ul>
                <p>The Alborz Payment services deal with foreign currency conversion; that is, depositing money/funds in one currency and sending money/funds to a third party in another currency;</p>
                <p>Alborz Payment charges conversion fees for all foreign currency conversion transactions. Foreign currency conversions would be applied on all remittance transactions that the currency of recipient differs the currency of depositor. All fees for foreign currency conversion would be shown up front at the time of transaction and accepted by the Customer.&nbsp;</p>
                <p>We determine currency conversion rates based on competitive market rates. Alborz Payment is not responsible for any changes in currency price in the market and subsequently in Alborz Payment rates that may occur because of market fluctuation or other possible factors. Exchange rates are booked at time of transaction and not guaranteed by Alborz Payment.&nbsp;</p>
                <p>Alborz Payment reserves its right to refuse or decline any currency conversion request at our discretion.&nbsp;</p>
                <ul>
                  <li><strong>Fees and Taxes</strong>&nbsp;</li>
                </ul>
                <p>Fees for all transactions including deposits, withdrawals, and remittance are shown up front to you via the process and must be accepted before a transaction is initiated. All transactions and fees you do on Alborz Payment will be kept in your transaction history and are accepted to by you, the Customer, as part of this agreement.&nbsp;</p>
                <p>Alborz Payment&rsquo;s fees do not include any fees charged by your financial institution or bank(s), and Alborz Payment has no responsibility for these fees. Moreover, Alborz Payment is not responsible for the payment of any taxes or duties owed on funds sent using our system. Users are solely responsible for abiding by tax law in their jurisdiction.</p>
                <ul>
                  <li><strong>Closing Your Account</strong>&nbsp;</li>
                </ul>
                <ul>
                  <ul>
                    <li><strong>Closure by Customer</strong></li>
                  </ul>
                </ul>
                <p>You may terminate and close your account at any time by contacting Customer Support at <a href="tel:+1 (647) 745-4666">+1 (647) 745-4666</a> or<a href="mailto:&nbsp;contact@alborzpayment.com">&nbsp;contact@alborzpayment.com</a></p>
                <p>Any available balance at time of closure will be sent to you through Interac e-transfer or by paper check. You accept that your information will be stored for a minimum of 7 years before being deleted in our systems and that all obligations contained in this agreement remain in force after an account is closed. You remain responsible for any pending transactions, fees, or reversed/disputed/chargeback transactions after closure of your Alborz Payment account.</p>
                <ul>
                  <ul>
                    <li><strong>Alborz Payment</strong><strong> can close your Alborz Payment Account</strong></li>
                  </ul>
                </ul>
                <p>Alborz Payment, in its sole discretion, may suspend or terminate this Customer Compliance and access to our services for any reason and at any time with or without notice to you. Any balance available at time of closure will be available for withdrawal to your bank account or by paper check.</p>
                <p>We may close your Account for the reasons below, or others:</p>
                <ul>
                  <li>Your contravention of any of the Terms of Use contained herein</li>
                  <li>Third party request from law enforcement or other regulatory body</li>
                  <li>Suspicion you are involved in fraudulent activity, money laundering, terrorism financing, or other illicit activity</li>
                  <li>Suspicion of third-party transactions or criminal activity</li>
                  <li>Suspicion that your Alborz Payment Account has been breached/accessed/operated by an unauthorized party</li>
                </ul>
                <p>In the case of any closure/termination you may request your transaction history and receive it from Alborz Payment Customer Support to the email address on your account. You remain responsible for any pending transactions, fees, or reversed/disputed/chargeback transactions after closure.&nbsp;</p>
                <ul>
                  <li><strong>Communications</strong></li>
                </ul>
                <p>Alborz Payment is required to communicate with you on an ongoing basis for issues of account operation, maintenance, and to market additional services to you.&nbsp;By accepting this Customer Agreement, you agree that we can communicate with you electronically either by email to the email address you provided, call/SMS at the phone number you provided, via physical mail to your home address, or by posting notices on the Website. Alborz Payment is not responsible for data, roaming, and other fees charged by your telephone, mobile, or internet service provider(s).&nbsp;</p>
                <h2><strong>4.</strong> <strong>Issues that May Occur</strong></h2>
                <ul>
                  <li><strong>Reversals and Chargebacks</strong></li>
                </ul>
                <p>You, the customer, are responsible for all reversals, chargebacks, claims, fees, penalties, fines, and other liability incurred by Alborz Payment caused by or arising out of your breach of this Customer Compliance, and/or your use of the Services. You agree to reimburse Alborz Payment for any and all such liability. Whenever a transaction is reversed, Alborz Payment will refund or reverse the transaction from your Alborz Payment Account in the same currency as the original transaction. If your Alborz Payment Account balance for a particular currency is insufficient to cover the amount of a refund or reversal, your Alborz Payment account will incur a negative balance. Customers and users are responsible to fund their accounts and clear negative balances promptly.</p>
                <p>Payments may be invalidated and reversed by Alborz Payment in the following, and other cases:&nbsp;</p>
                <ul>
                  <li>Our investigation of a bank reversal finds that the transaction was fraudulent</li>
                  <li>Alborz Payment sent the payment to you in error</li>
                  <li>Payment was not honored or reversed by your bank&nbsp;</li>
                  <li>You received the payment for activities that violated this Customer Compliance, Alborz Payment Privacy Policy, or any other agreement between you and Alborz Payment</li>
                  <li>You have a negative balance on your current or a duplicate account</li>
                  <li>Alborz Payment has a claim against you for the funds</li>
                  <li>Negative Account Balances&nbsp;</li>
                </ul>
                <p>Any negative balance on your Alborz Payment Account, happened for any reason, is your responsibility as account holder. Alborz Payment may deduct amounts owed from money you upload or money you receive into your Alborz Payment Account.In the case of a negative balance you are responsible for funding your account within 14 days of negative balance to repay Alborz Payment. Negative balances that last for 14 or more calendar days may be subject to collections action by Alborz Payment or third party agencies that we contract or take such other reasonable actions to recover the negative balance from you, for example, we may use a debt collection service or take further legal actions. Your information will be shared with the agencies for the purpose of recovering debts, negative balances, and outstanding funds considered by Alborz Payment.&nbsp;</p>
                <ul>
                  <li><strong>Unauthorized Transactions</strong> <strong>and Errors&nbsp;</strong></li>
                </ul>
                <p>To be safe against unauthorized activity and errors, you, as the user, should log into and review your Account and transaction history, regularly. All transactions carried out on your account result in automated receipt emails to your email address on file. You are responsible to review these in a timely fashion and notify Alborz Payment if you feel any activity is unauthorized or erroneous. &nbsp;</p>
                <p>If you do not keep your login credentials secure, and consequently, any transactions occur, Alborz Payment is not responsible for whatsoever. This includes any activity by you resulting in disclosure of your credentials such as sharing credentials with family/friends/any person, storing them in a vulnerable or unsafe way/location, or using public/shared computers to access your account. If you suspect your account credentials may have been compromised, change your account password and contact Customer Support immediately at <a href="tel:+1 (647) 745-4666">+1 (647) 745-4666</a> or<a href="mailto:&nbsp;contact@alborzpayment.com">&nbsp;contact@alborzpayment.com</a>.&nbsp;</p>
                <ul>
                  <li><strong>Complaints</strong></li>
                </ul>
                <p>If you have a question or complaint regarding the Services, please send an e-mail through our to&nbsp;<a href="mailto:contact@alborzpayment.com">contact@alborzpayment.com</a> or call at <a href="tel:+1 (647) 745-4666">+1 (647) 745-4666</a>. Please note that e-mail communications will not necessarily be secure; accordingly you should not include any sensitive information in your e-mail correspondence with us.</p>
                <p>&nbsp;</p>
                <h2><strong>5.</strong> <strong>Technology</strong></h2>
                <p>&nbsp;</p>
                <ul>
                  <li><strong>Linking to Alborz Payment Website</strong></li>
                </ul>
                <p>You may a to our Website, provided that you comply with the terms and conditions of this Customer Agreement, and follow certain rules. You may a to our Website, provided:&nbsp;</p>
                <ul>
                  <li>You do so in a way that is fair and legal and does not damage our reputation or take advantage of it</li>
                  <li>You do not suggest any form of association, approval or endorsement on our part where none exists</li>
                  <li>You do not frame our Website on any other site</li>
                  <li>You do not present or hold yourself out as an authorized representative or agent of Alborz Payment</li>
                  <li>You do not refer to any of our Services as &ldquo;banking&rdquo; services</li>
                  <li>You do not represent yourself as a referral partner, affiliate, or agent of Alborz Payment</li>
                </ul>
                <p>We reserve the right to revoke such withdraw linking permission without notice and for any reason.&nbsp;</p>
                <ul>
                  <li><strong>Information Security&nbsp;</strong></li>
                </ul>
                <p>You, the customer, are responsible for configuring your information technology, computer programs, software and platform in order to access Alborz Payment Services. You should use your own virus protection software. You must not misuse our Services by introducing viruses, trojans, worms, logic bombs or other materials which are malicious or technologically harmful. You must not attempt to gain unauthorized access to the Services, or our Website, our servers, computers or databases. You are responsible for your own data, device, and personal security. You must not attack the Services, including via our Website with any type of denial-of-service attack. By breaching this provision, you would commit a criminal offence under applicable law. We may report any such breach to the relevant law enforcement authorities and we may co-operate with those authorities by disclosing your identity or other information to them. In the case of such a breach, your right to access and use our Website and/or our Services will cease immediately without notice, and you must immediately cease all such access and use.</p>
                <ul>
                  <li><strong>Third Party Services</strong>&nbsp;</li>
                </ul>
                <p>You acknowledge and agree that Alborz Payment may engage third party partners and providers in order to provide and deliver you the Services. Different aspects of the services including verifying identities, processing transactions, facilitating payments, and so on require the transmission of your information to a third party under these Terms of Use and our&nbsp;Privacy Policy. Note that registering an account on Alborz Payment website and using the Services requires initial and ongoing acceptance of these Terms of Use.</p>
                <p>We do not take responsibility for the accuracy, validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or safety of any third party information or service at any time.</p>
                <h2><strong>6.</strong> <strong>Limitation of Liability</strong></h2>
                <ul>
                  <li><strong>Limitation of Alborz Payment&rsquo;s Liability</strong></li>
                </ul>
                <p>Alborz Payment only accepts liability for damages that they are expressly required to be liable for under law. Alborz Payment will not be liable for any damages, if your province/jurisdiction does not expressly require Alborz Payment be liable for any damages.</p>
                <p>You understand, agree, and warrant that Alborz Payment, including its owners, officers, executives, directors, agents, joint ventures, employees, contractors, related companies, investors, and other associated parties are not liable for any real or implied loss of profits, or any special, incidental, consequential damages (including without limitation damages for loss of data or loss of business) caused from customer use of the Services. Our liability to you is limited to the actual amount of direct damages as dictated by applicable law in your jurisdiction. Where your ability to use the Alborz Payment service is interrupted or prevented due to the clauses of these Terms of Use OR your own contravention of the Terms of Use we accept no liability whatsoever.</p>
                <p>Alborz Payment reserves the right to modify its policies and this Customer Compliance at any time consistent with the provisions outlined herein.</p>
                <ul>
                  <li><strong>Service Availability&nbsp;</strong></li>
                </ul>
                <p>We will try to make sure our Services are available to you in the time you need them. Nevertheless, we do not guarantee that our services will be uninterrupted or always be available. We have the right to suspend, withdraw, terminate, change, or modify all, some, or any part of our Service without notice. In the case that for any reason our services are unavailable in whole or in part at any time or for any period, we will not be liable to you.&nbsp;</p>
                <p>Further, you are responsible to set up any necessary facilities for you to have access to the services including all hardware and telecommunications services such as having access to a computer device and internet connection.&nbsp;</p>
                <ul>
                  <li><strong>No Warranty</strong>&nbsp;</li>
                </ul>
                <p>Alborz Payment provides the services &ldquo;as is&rdquo; and without any warranty or condition.</p>
                <ul>
                  <li><strong>Events Outside of Our Control&nbsp;</strong></li>
                </ul>
                <p>&ldquo;AlborzPayment.com,&rdquo; &ldquo;AlborzPayment.ca,&rdquo; &ldquo;AlborzPayment&rdquo;, &ldquo;Alborz Payment&rdquo;, &ldquo;Alborz Holding Tech&rdquo; are all logos related to the Services that are either trademarks or registered trademarks of Alborz Payment. You may not use, copy, imitate, or modify any logos, brands, marketing materials, public face or scripts of Alborz Payment without prior written consent. Any direct or indirect use of any of the Alborz Payment materials means plagiarism or breaking of copyright and, where applicable, may result in criminal or civil fines/penalties.&nbsp;</p>
                <ul>
                  <li><strong>Alborz Payment Is A Money Services Business (MSB)</strong></li>
                </ul>
                <p>Our relationship with you under this Customer Compliance with you is as a money service business, and Alborz Payment is an independent contractor for all purposes. Alborz Payment is not your agent or trustee. Alborz Payment provides money transfer and foreign exchange services. Alborz Payment is not a bank or financial institution and is not a payment processor in any type.</p>
                <ul>
                  <li><strong>Entire agreement&nbsp;</strong></li>
                </ul>
                <p>This Terms of Use along with the&nbsp;Privacy Policy and other information provided on the Alborz Payment website includes the entire agreement between you, as the Customer, and Alborz Payment, as the Money Service Business. In case we update our Terms of Use or Privacy Policy, we will communicate to you in writing using the information on your account and also at the time of your first login after update. Your continued use of or access to Alborz Payment website following the posting of any changes constitutes acceptance of those changes.</p>
                <ul>
                  <li><strong>Law and Jurisdiction</strong></li>
                </ul>
                <p>The provision of the Services and any dispute or claim arising out of the provision of the Services is governed by the laws of the Provinces of Ontario and British Columbia, and the federal laws of Canada applicable therein.</p>
                <ul>
                  <li><strong>Language</strong>&nbsp;</li>
                </ul>
                <p>The parties have accepted that this Customer Compliance and any related documents will be written in the English language.</p>
              </Col>
            </Row>
          </Container>
        </section>
        <ContactUs/>
      </main>
    );
  }
}

export default Terms;
