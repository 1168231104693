import * as React from 'react';
import {Translate, TranslateWithoutContext} from '../i18n';
import {commafy} from 'services/utilities/farsify';
import {findIndex} from 'lodash';
import {
    Button,
    Card,
    CardBody,
    Input,
    UncontrolledTooltip,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
} from "reactstrap";
import StaticsRepo from 'repository/Statics';
import Marquee from "react-fast-marquee";

import './style.scss';

class Exchange extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currencies: [],
            sendAmount: props.value ? props.value.sendAmount : 1000,
            receiveAmount: 1000,
            transferFeeAmount: 0,
            paymentProcessingFeeAmount: 0,
            exchangeRate: 1,
            transactionConfig: {
                _id: '',
                created_at: 0,
                created_by: '',
                fee_amount: 5,
                fee_currency_id: '',
                note_en: '',
                note_fa: '',
            },
            showCalculation: false,
            sendFocus: false,
            receiveFocus: false,
        };

        this.translate = TranslateWithoutContext.getInstance();
        this.staticsRepo = StaticsRepo.getInstance();
    }

    componentDidMount() {
        this.rtl = localStorage.getItem('ap.theme.dir') !== 'ltr';
        if (this.props.innerRef) {
            this.props.innerRef(this);
        }
        const promises = [];
        promises.push(this.staticsRepo.getCurrencyList());
        promises.push(this.staticsRepo.getTransactionConfig());
        Promise.all(promises).then((arrRes) => {
            const res = arrRes[0];
            if (res.currencies && (res.currencies || []).length >= 2) {
                const {value} = this.props;
                const state = {
                    currencies: res.currencies || [],
                    transactionConfig: arrRes[1],
                };
                if (value) {
                    const sendIndex = findIndex(res.currencies || [], {_id: value.sendCurrency});
                    if (sendIndex > -1) {
                        state['sendCurrency'] = res.currencies[sendIndex];
                    } else {
                        state['sendCurrency'] = res.currencies[0];
                    }
                    const receiveIndex = findIndex(res.currencies || [], {_id: value.receiveCurrency});
                    if (receiveIndex > -1) {
                        state['receiveCurrency'] = res.currencies[receiveIndex];
                    } else {
                        state['receiveCurrency'] = res.currencies[0];
                    }
                } else {
                    state['sendCurrency'] = res.currencies[0];
                    state['receiveCurrency'] = res.currencies[1];
                }
                this.setState(state, () => {
                    this.getExchangeRate();
                    this.getTransferFee();
                });
            }
        });
        window.addEventListener('lang_changed', this.langChangeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('lang_changed', this.langChangeHandler);
    }

    submit() {
        this.submitHandler();
    }

    render() {
        const {
            currencies,
            sendCurrency,
            receiveCurrency,
            sendAmount,
            receiveAmount,
            transferFeeAmount,
            paymentProcessingFeeAmount,
            exchangeRate,
            showCalculation,
            sendFocus,
            receiveFocus,
            transactionConfig
        } = this.state;
        const currencyName = sendCurrency ? sendCurrency.name : '';
        const sendFloatingPoint = sendCurrency ? (sendCurrency.floating_point || 0) : 0;
        const receiveFloatingPoint = receiveCurrency ? (receiveCurrency.floating_point || 0) : 0;
        return (
            <Card className="exchange-box-container shadow border-0">
                <CardBody>
                    {this.getNoteContent()}
                    <div className="exchange-box-title">
                        <div className="label">
                            <Translate>Exchange</Translate>
                        </div>
                        <div className="icon" color="primary" onClick={this.switchCurrencyHandler}>
                            <div className="icon icon-shape icon-shape-secondary rounded-circle" id="swap-tooltip">
                                <i className="fa fa-exchange"/>
                            </div>
                            <UncontrolledTooltip placement="right" target="swap-tooltip">
                                <Translate>Swap</Translate>
                            </UncontrolledTooltip>
                        </div>
                    </div>
                    <div className="exchange-box-content">
                        <Row form className="exchange-input-row">
                            <Col sm={9} xs={12}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><Translate>Send</Translate></InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        className="input"
                                        value={commafy(sendAmount, sendFocus ? 0 : sendFloatingPoint)}
                                        type="text"
                                        onChange={this.amountChangeHandler('send')}
                                        onFocus={this.sendFocus(true)}
                                        onBlur={this.sendFocus(false)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Input
                                    className="select"
                                    type="select"
                                    value={sendCurrency ? sendCurrency._id : ''}
                                    onChange={this.currencyChangeHandler('send')}
                                    classes={{
                                        select: 'exchange-box-select'
                                    }}
                                >
                                    {currencies.map((currency) => {
                                        return (
                                            <option key={currency._id} value={currency._id}>{currency.name}</option>);
                                    })}
                                </Input>
                            </Col>
                        </Row>
                        {transactionConfig?.fee_currency_id !== '' && !transactionConfig?.visual?.hide_landing_calculation ?
                            <Row>
                                <Col sm={12} xs={12}>
                                    <div className="fees-container">
                                        <div className="fee-calculation" onClick={this.toggleCalculationHandler}><i
                                            className="ni ni-sound-wave"/> {showCalculation ?
                                            <Translate>Hide calculation</Translate> :
                                            <Translate>Show calculation</Translate>}</div>
                                        {showCalculation && <div className="fee-row">
                                            <div
                                                className="fee-amount">+ {commafy(transferFeeAmount, sendFloatingPoint)} {currencyName}</div>
                                            <div className="fee-label"><Translate>Our fee</Translate></div>
                                        </div>}
                                        {showCalculation && <div className="fee-row">
                                            <div
                                                className="fee-amount">+ {commafy(paymentProcessingFeeAmount, sendFloatingPoint)} {currencyName}</div>
                                            <div className="fee-label"><Translate>Bank fee</Translate></div>
                                        </div>}
                                        {showCalculation && <div className="fee-row">
                                            <div
                                                className="fee-amount">+ {commafy(sendAmount, sendFloatingPoint)} x {commafy(exchangeRate, 4)}</div>
                                            <div className="fee-label"><Translate>Conversion rate</Translate></div>
                                        </div>}
                                        {!showCalculation && <div className="fee-row">
                                            <div
                                                className="fee-amount">+ {commafy(transferFeeAmount + paymentProcessingFeeAmount, sendFloatingPoint)} {currencyName}</div>
                                            <div className="fee-label"><Translate>Total fees</Translate></div>
                                        </div>}
                                        <div
                                            className={'fee-row total-amount' + (showCalculation ? ' ex-border-top' : '')}>
                                            <div
                                                className="fee-amount">{commafy(transferFeeAmount + sendAmount + paymentProcessingFeeAmount, sendFloatingPoint)} {currencyName}</div>
                                            <div className="fee-label"><Translate>Total amount</Translate></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row> : <div className="exchange-gap"/>}
                        <Row form className="exchange-input-row">
                            <Col sm={9} xs={12}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><Translate>Receive</Translate></InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        className="input"
                                        value={commafy(receiveAmount, receiveFocus ? 0 : receiveFloatingPoint)}
                                        onChange={this.amountChangeHandler('receive')}
                                        onFocus={this.receiveFocus(true)}
                                        onBlur={this.receiveFocus(false)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Input className="select"
                                       type="select"
                                       value={receiveCurrency ? receiveCurrency._id : ''}
                                       onChange={this.currencyChangeHandler('receive')}
                                       classes={{
                                           select: 'exchange-box-select'
                                       }}
                                >
                                    {currencies.map((currency) => {
                                        return (
                                            <option key={currency._id} value={currency._id}>{currency.name}</option>);
                                    })}
                                </Input>
                            </Col>
                            <Col sm={12} xs={12}>
                                <div className="exchange-box-footer">
                                    <div className="exchange-box-statement">{this.exchangeStatement()}</div>
                                    <div className="exchange-box-button">
                                        <Button block color="primary" onClick={this.submitHandler}>
                                            <Translate>Transfer</Translate> &nbsp;<i className="ni ni-spaceship"/>
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        );
    }

    getNoteContent() {
        const {transactionConfig} = this.state;
        if (!this.rtl && transactionConfig.note_en && transactionConfig.note_en.length > 0) {
            return <div className="transaction-note">
                {transactionConfig.note_en.length > 64 ? <Marquee direction="left" gradientWidth={20}>
                    <div className="inner">{transactionConfig.note_en}</div>
                </Marquee> : <div className="inner">{transactionConfig.note_en}</div>}
            </div>;
        } else if (this.rtl && transactionConfig.note_fa && transactionConfig.note_fa.length > 0) {
            return <div className="transaction-note">
                {transactionConfig.note_fa.length > 64 ? <Marquee direction="right" gradientWidth={20}>
                    <div className="inner">{transactionConfig.note_fa}</div>
                </Marquee> : <div className="inner">{transactionConfig.note_fa}</div>}
            </div>;
        }
        return null;
    }

    getExchangeRate(mode) {
        const {receiveCurrency, sendCurrency, receiveAmount, sendAmount} = this.state;
        if (!receiveCurrency || !sendCurrency) {
            return;
        }
        const exchangeRate = receiveCurrency.ratio / sendCurrency.ratio_rev;
        if (mode === 'receive' && receiveAmount > 0) {
            this.setState({
                exchangeRate,
                sendAmount: receiveAmount / exchangeRate,
            });
        } else if (sendAmount > 0) {
            this.setState({
                exchangeRate,
                receiveAmount: sendAmount * exchangeRate,
            });
        }
    }

    getTransferFee() {
        const {transactionConfig, sendCurrency, sendAmount} = this.state;
        if (transactionConfig.fee_currency_id === '' || !sendCurrency) {
            return;
        }
        this.staticsRepo.getCurrencyById(transactionConfig.fee_currency_id).then((currency) => {
            if (currency) {
                const exchangeRate = currency.ratio * sendCurrency.ratio_rev;
                const transferFeeAmount = transactionConfig.fee_amount * exchangeRate;
                const paymentProcessingFeeAmount = sendAmount ? this.getFee() * exchangeRate : 0;
                this.setState({
                    transferFeeAmount,
                    paymentProcessingFeeAmount,
                });
            }
        });
    }

    amountChangeHandler = (mode) => (e) => {
        const text = e.target.value;
        const amount = Number(text.replace(/[^0-9.]/g, ''));
        const state = {};
        state[mode === 'send' ? 'sendAmount' : 'receiveAmount'] = amount;
        this.setState(state, () => {
            this.getExchangeRate(mode);
            if (this.state.sendCurrency?.fee_type === 1) {
                this.getTransferFee();
            }
        });
    };

    switchCurrencyHandler = () => {
        const {sendCurrency, receiveCurrency} = this.state;
        this.setState({
            sendCurrency: receiveCurrency,
            receiveCurrency: sendCurrency,
        }, () => {
            this.getExchangeRate();
            this.getTransferFee();
        });
    };

    exchangeStatement() {
        const {sendCurrency, receiveCurrency, exchangeRate} = this.state;
        if (!sendCurrency || !receiveCurrency) {
            return '';
        }
        if (exchangeRate < 1) {
            return <>{`1 ${receiveCurrency.name}`}&nbsp;<i
                className="ni ni-bold-left"/>&nbsp;{`${commafy(1 / exchangeRate)} ${sendCurrency.name}`}</>;
        } else {
            return <>{`1 ${sendCurrency.name}`}&nbsp;<i
                className="ni ni-bold-right"/>&nbsp;{`${commafy(exchangeRate)} ${receiveCurrency.name}`}</>;
        }
    }

    toggleCalculationHandler = () => {
        this.setState({
            showCalculation: !this.state.showCalculation,
        });
    };

    currencyChangeHandler = (name) => (e) => {
        const {currencies, sendCurrency, receiveCurrency} = this.state;
        if (!sendCurrency || !receiveCurrency) {
            return;
        }
        const id = e.target.value;
        const index = findIndex(currencies, {_id: e.target.value});
        let state = {};
        if (index > -1) {
            const currency = currencies[index];
            if (name === 'send') {
                if (receiveCurrency._id === id) {
                    state = {
                        sendCurrency: currency,
                        receiveCurrency: sendCurrency,
                    };
                } else {
                    state = {
                        sendCurrency: currency,
                    };
                }
            } else {
                if (sendCurrency._id === id) {
                    state = {
                        receiveCurrency: currency,
                        sendCurrency: receiveCurrency,
                    };
                } else {
                    state = {
                        receiveCurrency: currency,
                    };
                }
            }
        }
        this.setState(state, () => {
            this.getExchangeRate();
            this.getTransferFee();
        });
    };

    sendFocus = (focus) => () => {
        this.setState({
            sendFocus: focus,
        });
    }

    receiveFocus = (focus) => () => {
        this.setState({
            receiveFocus: focus,
        });
    }

    submitHandler = () => {
        const {sendAmount, sendCurrency, receiveCurrency} = this.state;
        if (sendCurrency && receiveCurrency) {
            window.location.href = `/panel#/transfer?sa=${sendAmount}&sc=${sendCurrency._id}&rc=${receiveCurrency._id}`;
        } else {
            window.location.href = `/panel`;
        }
        // if (this.props.onChange) {
        //     const { exchangeRate, paymentProcessingFeeAmount, receiveAmount, receiveCurrency, sendAmount, sendCurrency, transferFeeAmount } = this.state;
        //     if (!receiveCurrency || !sendCurrency) {
        //         return;
        //     }
        //     this.props.onChange({
        //         exchangeRate,
        //         paymentProcessingFeeAmount,
        //         receiveAmount,
        //         receiveCurrency,
        //         sendAmount,
        //         sendCurrency,
        //         transferFeeAmount,
        //     });
        // }
    };

    langChangeHandler = () => {
        this.rtl = localStorage.getItem('ap.theme.dir') !== 'ltr';
        this.forceUpdate();
    }

    getFee() {
        const {sendCurrency, sendAmount} = this.state;
        if (!sendCurrency) {
            return 0;
        }
        if (sendCurrency.fee_type === 0) {
            return sendCurrency.payment_processing_fee;
        }
        const len = sendCurrency.fee_conditions?.length || 0;
        if (sendCurrency.fee_type === 1 && len > 2) {
            for (let i = len - 1; i >= 0; i--) {
                const cond = sendCurrency.fee_conditions?.[i];
                if (cond && sendAmount > cond.amount) {
                    if (i + 1 === len) {
                        return sendCurrency.payment_processing_fee;
                    }
                    return sendCurrency.fee_conditions?.[i + 1].fee || sendCurrency.payment_processing_fee;
                }
            }
        }
        return sendCurrency.payment_processing_fee;
    }
}

export default Exchange;

