const translations = require('./translations.json');

export class TranslateWithoutContext {
    static getInstance() {
        if (!this.instance) {
            this.instance = new TranslateWithoutContext();
        }

        return this.instance;
    }

    static instance;
    translations = {};
    defaultLanguage = 'en';

    constructor() {
        this.translations = translations;
    }

    _getText(text, locale) {
        const lng = locale || this.defaultLanguage;
        if (text) {
            if (this.translations[text] && this.translations[text][lng]) {
                return this.translations[text][lng];
            }
        }
        return text;
    }

    setLanguage(lang) {
        this.defaultLanguage = lang;
    }
}

export const Translate = ({children}) => {
    const translate = TranslateWithoutContext.getInstance();;

    return translate._getText(children);
}

export default Translate;