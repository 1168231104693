import Http from '../../services/http';

const C_CACHE_TTL = 300000;

export default class StaticsRepo {
    static getInstance() {
        if (!this.instance) {
            this.instance = new StaticsRepo();
        }

        return this.instance;
    }

    static instance;
    http = null;

    currencyCache = null;
    countryCache = null;
    transactionConfigCache = null;

    constructor() {
        this.http = Http.getInstance();
        this.currencyCache = null;
        this.countryCache = null;
        this.transactionConfigCache = null;
    }

    getCurrencyList() {
        const now = Date.now();
        if (this.currencyCache && (now - this.currencyCache.lastUpdated) < C_CACHE_TTL) {
            return Promise.resolve({ currencies: this.currencyCache.currencies });
        }
        return this.http.get('/static/currency/list', {}).then((res) => {
            return res.data.data;
        }).then((res) => {
            if (res.currencies) {
                const currencies = res.currencies.map((c) => ({
                    ...c,
                    fee_conditions: [{
                        amount: 0,
                        fee: c.payment_processing_fee,
                    }, ...(c.fee_conditions || []), {
                        amount: 10000000000,
                        fee: c.payment_processing_fee,
                    }],
                }));
                const map = {};
                currencies.forEach((c, i) => {
                    map[c._id] = i;
                });
                this.currencyCache = {
                    currencies,
                    currencyMap: map,
                    lastUpdated: now,
                };
                res.currencies = currencies;
            }
            return res;
        });
    }

    getCurrencyById(id) {
        if (this.currencyCache && this.currencyCache.currencyMap[id]) {
            return Promise.resolve(this.currencyCache.currencies[this.currencyCache.currencyMap[id]]);
        } else {
            return this.getCurrencyList().then(() => {
                if (this.currencyCache && this.currencyCache.currencyMap[id] !== undefined) {
                    return this.currencyCache.currencies[this.currencyCache.currencyMap[id]];
                } else {
                    return undefined;
                }
            });
        }
    }

    getCountryList() {
        const now = Date.now();
        if (this.countryCache && (now - this.countryCache.lastUpdated) < C_CACHE_TTL) {
            return Promise.resolve({ countries: this.countryCache.countries });
        }
        return this.http.get('/static/country/list', {}).then((res) => {
            return res.data.data;
        }).then((res) => {
            if (res.countries) {
                const map = {};
                res.countries.forEach((c, i) => {
                    map[c._id] = i;
                });
                this.countryCache = {
                    countries: res.countries,
                    countryMap: map,
                    lastUpdated: now,
                };
            }
            return res;
        });
    }

    getCountryById(id) {
        if (this.countryCache && this.countryCache.countryMap[id]) {
            return Promise.resolve(this.countryCache.countries[this.countryCache.countryMap[id]]);
        } else {
            return this.getCurrencyList().then(() => {
                if (this.countryCache && this.countryCache.countryMap[id] !== undefined) {
                    return this.countryCache.countries[this.countryCache.countryMap[id]];
                } else {
                    return undefined;
                }
            });
        }
    }

    getTransactionConfig() {
        const now = Date.now();
        if (this.transactionConfigCache && (now - this.transactionConfigCache.lastUpdated) < C_CACHE_TTL) {
            return Promise.resolve(this.transactionConfigCache.data);
        }
        return this.http.get('/static/transaction/config', {}).then((res) => {
            return res.data.data;
        }).then((res) => {
            this.transactionConfigCache = {
                data: res,
                lastUpdated: now,
            };
            return res;
        });
    }
}
