/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import { TranslateWithoutContext } from "components/i18n";
import Navbar from "components/Navbars/Navbar";
import Main from "views/main/index.js";
import Terms from "views/terms";
import Privacy from "views/privacy";
import PrivacyFa from "views/privacy_fa";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { IrFlag, CaFlag } from "components/SVG";

import './style.scss';

let rtl = localStorage.getItem('ap.theme.dir') === 'rtl';
let lang = localStorage.getItem('ap.theme.lang') || 'fa';

const init = () => {
  // const bodyEl = document.querySelector('body');
  // if (bodyEl) {
  //   bodyEl.setAttribute('dir', rtl ? 'rtl' : 'ltr');
  // }
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    htmlEl.setAttribute('direction', rtl ? 'rtl' : 'ltr');
  }
  TranslateWithoutContext.getInstance().setLanguage(lang);
}

const saveConfig = () => {
  localStorage.setItem('ap.theme.dir', rtl ? 'rtl' : 'ltr');
  localStorage.setItem('ap.theme.lang', lang);
}

const routes = () => {
  return (<Switch>
    <Route path="/" exact render={props => <Main {...props} />} />
    <Route
      path="/terms"
      exact
      render={props => <Terms {...props} />}
    />
    <Route
      path="/privacy"
      exact
      render={props => lang === 'fa' ? <PrivacyFa {...props} /> : <Privacy {...props} />}
    />
    {/*<Route*/}
    {/*  path="/team"*/}
    {/*  exact*/}
    {/*  render={props => <Team {...props} />}*/}
    {/*/>*/}
    <Redirect to="/" />
  </Switch>);
};

const LanguageDialog = ({ onLangChange }) => {
  const [open, setOpen] = useState(!Boolean(localStorage.getItem('ap.theme.lang')));
  const toggle = () => setOpen(!open);
  const selectLang = (lang) => () => {
    onLangChange(lang);
    setOpen(false);
  };

  return (<Modal
    className="language-modal"
    isOpen={open}
    toggle={toggle}
    backdrop="static"
  >
    <ModalHeader toggle={toggle}>Language/زبان</ModalHeader>
    <ModalBody className="language-modal-body">
      Please choose your language <br />
      لطفا زبان خود را انتخاب کنید
      <div className="language-modal-list">
        <div className="language-modal-item" onClick={selectLang('fa')}>
          <div className="language-modal-item-text">فارسی</div>
          <IrFlag />
        </div>
        <div className="language-modal-item" onClick={selectLang('en')}>
          <div className="language-modal-item-text">English</div>
          <CaFlag />
        </div>
      </div>
    </ModalBody>
  </Modal>)
};

const App = () => {
  const [time, setTime] = useState(0);
  init();

  const langChangeHandler = (language) => {
    lang = language;
    rtl = language === 'fa';
    init();
    saveConfig();
    setTime(Date.now());
    const event = new CustomEvent('lang_changed', { detail: { lang } });
    window.dispatchEvent(event);
  };

  return (<HashRouter>
    <Navbar onLangChange={langChangeHandler} time={time} />
    <LanguageDialog onLangChange={langChangeHandler} />
    {routes()}
  </HashRouter>);
};

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
