export const enToFa = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    if (typeof str === 'number') {
        str = str.toString();
    }
    const fa = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '،'];
    const en = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];
    for (let i = 0;i < fa.length;i++) {
        str = str.replace(new RegExp(en[i], 'g'), fa[i]);
    }
    return str;
};

export const faToEn = (str) => {
    if (str === null || str === undefined) {
        return '';
    }
    if (typeof str === 'number') {
        str = str.toString();
    }
    const fa = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '،'];
    const en = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','];
    for (let i = 0;i < en.length;i++) {
        str = str.replace(new RegExp(fa[i], 'g'), en[i]);
    }
    return str;
};

export const commafy = (num, fix) => {
    if (isNaN(num)) {
        num = 0.00;
    }
    fix = fix || 0;
    if (num === null) {
        return 0;
    }
    // if (num < 0.01) {
    //     return num.toFixed(8);
    // }
    let postFix = '';
    if (fix && fix > 0 && !isNaN(num)) {
        postFix = `${(num - Math.floor(num)).toFixed(fix)}`.substr(1);
    }
    return `${Math.floor(num).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}${postFix}`;
};

export const trimRatio = (ratio) => {
    if (ratio < 1) {
        return 1 / ratio;
    }
    return ratio;
};
