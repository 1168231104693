/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Translate} from "components/i18n";
import StaticsRepo from "repository/Statics";
import {CaFlag} from "components/SVG";
import {commafy} from 'services/utilities/farsify';
import Marquee from "react-fast-marquee";
import {C_EMPTY_ID, getFilePath} from "../../services/http";

import './index.scss';

class Banner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            base: undefined,
            rtl: localStorage.getItem('ap.theme.dir') !== 'ltr',
        };

        this.staticsRepo = StaticsRepo.getInstance();
    }

    componentDidMount() {
        this.staticsRepo.getCurrencyList().then((res) => {
            const list = res.currencies.filter(o => o.landing_visible);
            const base = res.currencies.find(o => o.landing_base);
            if (base && list.length > 0) {
                const tList = list.map((item) => {
                    const rateSell = base.ratio_rev / item.ratio;
                    const rateBuy = base.ratio / item.ratio_rev;
                    return {
                        ...item,
                        rateSell,
                        rateBuy,
                        img: item.picture._id !== C_EMPTY_ID ? getFilePath(`${item.picture.hash}/${item.picture.path}`) : null,
                    };
                });
                this.setState({
                    list: tList,
                    base,
                });
            }
        });
        window.addEventListener('lang_changed', this.langChangeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('lang_changed', this.langChangeHandler);
    }

    render() {
        const {list, base} = this.state;
        return (
            <div className="exchange-banner">
                <div className="exchange-container">
                    <Marquee direction={this.state.rtl ? 'left' : 'left'} gradientWidth={20} gradientColor="#182A4D"
                             speed={75}>
                        {list.map((item) => {
                            return (
                                <div key={item._id} className="currency-item">
                                    <div className="flag">
                                        {item.img ? <img src={item.img} alt={item.name}/> : <CaFlag/>}
                                        <div className="name">{item.name}</div>
                                    </div>
                                    <div className="price">
                                        <div className="label"><Translate>Sell</Translate></div>
                                        <div className="value">
                                            {commafy(item.rateSell)}
                                            <div className="unit">{base?.name}</div>
                                        </div>
                                    </div>
                                    <div className="price">
                                        <div className="label"><Translate>Buy</Translate></div>
                                        <div className="value">
                                            {commafy(item.rateBuy)}
                                            <div className="unit">{base?.name}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Marquee>
                </div>
            </div>
        )
    }

    langChangeHandler = () => {
        this.setState({
            rtl: localStorage.getItem('ap.theme.dir') !== 'ltr',
        });
    }
}

export default Banner;
