import React from "react";
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";

import { Translate, TranslateWithoutContext } from "components/i18n/index.js";

const translate = TranslateWithoutContext.getInstance();

export class ContactUs extends React.Component {
    state = {};
    render() {
        return <>
            <section className="section section-lg bg-gradient-default" id="contact-us-section">
                <Container className="pt-lg pb-300">
                    <Row className="text-center justify-content-center">
                        <Col lg="10">
                            <h2 className="display-3 text-white"><Translate>Have a question?</Translate></h2>
                            <p className="lead text-white">
                                <Translate>Please contact us if there is anything that we can help with</Translate>
                            </p>
                            <p className="lead text-white mt-0">
                                <a href="tel:+1 (647) 745-4666">+1 (647) 745-4666</a>
                                {/*<h6 className="text-white" style={{ direction: 'ltr' }}>5508 Yonge St, Toronto, Ontario</h6>*/}
                            </p>
                        </Col>
                    </Row>
                </Container>
                {/* SVG separator */}
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-white"
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
            </section>
            <section className="section section-lg pt-lg-0 section-contact-us">
                <Container>
                    <Row className="justify-content-center mt--300">
                        <Col lg="8">
                            <Card className="bg-gradient-secondary shadow">
                                <CardBody className="p-lg-5">
                                    <h4 className="mb-1"><Translate>Contact Us</Translate></h4>
                                    <p className="mt-0">
                                        <Translate>Write for us</Translate>
                                    </p>
                                    <FormGroup
                                        className={classnames("mt-5", {
                                            focused: this.state.nameFocused
                                        })}
                                    >
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-user-run" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder={translate._getText('Your name')}
                                                type="text"
                                                onFocus={e => this.setState({ nameFocused: true })}
                                                onBlur={e => this.setState({ nameFocused: false })}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup
                                        className={classnames({
                                            focused: this.state.emailFocused
                                        })}
                                    >
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder={translate._getText('Email address')}
                                                type="email"
                                                onFocus={e => this.setState({ emailFocused: true })}
                                                onBlur={e => this.setState({ emailFocused: false })}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            className="form-control-alternative"
                                            cols="80"
                                            name="name"
                                            placeholder={translate._getText('Type a message...')}
                                            rows="4"
                                            type="textarea"
                                        />
                                    </FormGroup>
                                    <div>
                                        <Button
                                            block
                                            className="btn-round"
                                            color="default"
                                            size="lg"
                                            type="button"
                                        >
                                            <Translate>Send Message</Translate>
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <h6 className="text-center mt-4" style={{ direction: 'ltr' }}>Online Exchange Service</h6>
                            <h6 className="text-center mt-4" style={{ direction: 'ltr' }}>© Alborz Payment Inc 2024</h6>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>;
    }
}
