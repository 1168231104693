/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import { Translate } from "components/i18n/index.js";
import { ContactUs } from "../../components/ContactUs";

import './style.scss';

class Privacy extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <main ref="main">
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default" />
          <Container>
            <Row className="justify-content-center text-center mb-sm">
              <Col lg="8">
                <h2 className="display-3"><Translate>Privacy</Translate></h2>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <Row>
              <Col sm="12">
                <p>Alborz Payment is concerned about privacy issues and wants you to understand how we collect, use, disclose, and process your information. This Privacy Policy describes our practices in connection with information that we collect through our websites from which you are accessing this policy, including&nbsp;<a href="https://transferwise.com/"><strong>www.alborzpayment.com</strong></a>&nbsp;(the &ldquo;<strong>Websites</strong>&rdquo;), through the software applications made available by us for use on or through computers and mobile devices (the &ldquo;<strong>Apps</strong>&rdquo;), as well as through HTML-formatted email messages that we send to you that link to this Privacy Policy (collectively, including the Websites and Apps, the &ldquo;<strong>Services</strong>&rdquo;).</p>
                <p>For the purposes of this Privacy Policy, &ldquo;Personal Information&rdquo; means information about an identified or identifiable individual, excluding business contact information.</p>
                <p>By providing Personal Information, or other information, to us or using our Services, you confirm that you understand and consent to the collection, use, disclosure, and processing of your Personal Information and other information (or the Personal Information or other information of any other individual you provide) in the manner as set forth in this Privacy Policy, and you understand that this Privacy Policy is legally binding when you use our Services. Check the&nbsp;<strong>Customer Agreement</strong>&nbsp;for the meaning of defined words (those with capital letters) that are not defined in this Privacy Policy.</p>
                <p><strong>Information we may collect about you</strong></p>
                <p>We collect your Personal Information and other information when you provide it to us, when you use the Services and when you register for the Services (e.g., an Alborz Payment Account). We may collect the following Personal Information from you: your name, mailing address, email address, phone number, financial information (including credit card, debit card, bank account information or bank account login credentials), occupation, personal description and photograph.</p>
                <p>We must by law confirm your identity in order to provide certain Services to you e.g., if you send or receive certain high-value or high volume transactions, or as needed to comply with our obligations under applicable laws and regulations, including but not limited to our anti-money laundering obligations. In order to meet our compliance requirements, we may need to collect valid identification documents, such as a copy of your driver&rsquo;s license, passport or similar identity document, and/or additional commercial and/or financial information from you as required. If you submit Personal Information relating to other people to us or to our service providers in connection with the Services, you warrant that you have obtained the necessary consents from such individual and have authority to disclose his/her Personal Information to us, as well as his/her consent to our collection, use and disclosure of such personal data, for the purposes set forth in this Privacy Policy.</p>
                <p>Technical Information we collect:</p>
                <p>details of the transactions you carry out on our Website or when using our Services, including geographic location from which the transaction originates;</p>
                <p>technical information, including the Internet Protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</p>
                <p>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through or from our Website (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our Customer Support number.</p>
                <p><strong>We may aggregate Personal Information.</strong></p>
                <p>Such aggregated information does not personally identify you or any other user of the Services. For example, we may aggregate Personal Information to calculate the percentage of our users who have a particular telephone area code.</p>
                <p><strong>Information we receive from other sources.</strong></p>
                <p>We may receive information about you if you use any of the other websites we operate or the other services we provide. We are also working closely with third parties and may receive information about you from them for example, banks and payment service providers you use to transfer money to us; banks and payment service providers of your recipient; business partners; sub-contractors in technical, payment and delivery services; advertising networks; analytics providers; search information providers; and credit reference agencies; public databases; and social media platforms.</p>
                <p>If you log in to our Services using your social media account (for example, Facebook or Google) we will receive relevant information that is necessary to enable our Services and authenticate you. The social media network will provide us with access to certain information that you have provided to them, including your name, profile image and e-mail address. We use such information, together with any other information you directly provide to us when registering or using our Services, to create your account and to communicate with you about the information, products and services that you request from us. You may also be able to specifically request that we have access to the contacts in your social media account so that you can send a referral link to your family and friends. We will use, disclose and store all of this information in accordance with this privacy policy.</p>
                <p><strong>Cookies and similar technologies.</strong></p>
                <p>Our Website uses cookies, some of which may collect your Personal Information or Technical Information, to distinguish you from other users of our Website. This helps us to provide you with a good experience and also allows us to improve our Website. Cookies allow us to collect information such as browser type, time spent on the Website, pages visited, language preferences, and other anonymous traffic data. We and our service providers use the information for security purposes, to facilitate navigation, to display information more effectively, to personalize your experience while using the Services and to recognize your computer in order to assist your use of the Services. We also gather statistical information about the use of the Services in order to continually improve their design and functionality, understand how they are used and assist us with resolving questions regarding them. Cookies further allow us to select which of our advertisements or offers are most likely to appeal to you and display them while you are on the Services. We may also use cookies or other technologies to track responses to our online advertisements.</p>
                <p>If you do not want information collected through the use of cookies, there is a simple procedure in most browsers that allows you to automatically decline cookies or be given the choice of declining or accepting the transfer to your computer of a particular cookie (or cookies) from a particular site. You may also wish to refer to&nbsp;<a href="http://www.allaboutcookies.org/manage-cookies/index.html"><strong>www.allaboutcookies.org/manage-cookies/index.html</strong></a>. If, however, you do not accept cookies, you may experience some inconvenience in your use of the Services. For example, we may not be able to recognize your computer, and you may need to login every time you visit.</p>
                <p>At this time we do not respond to browser do-not-track signals.</p>
                <p>Pixel tags (also known as web beacons and clear GIFs) may be used in connection with the Services to, among other things, track the actions of users (including email recipients), measure the success of our marketing campaigns and compile statistics about usage of the Services and response rates. We may use third-party analytics providers, such as Google Analytics and Mixpanel, which use cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends. These third parties may also collect information regarding the use of other websites, apps and online resources. You can learn about Google&rsquo;s practices by going to&nbsp;<a href="https://www.google.com/policies/privacy/%25E2%2580%258Cpartners/"><strong>www.google.com/policies/privacy/</strong><strong>&zwnj;</strong><strong>partners</strong></a>, and opt out of them by downloading the Google Analytics opt-out browser add-on, available at&nbsp;<a href="https://tools.google.com/dlpage/gaoptout"><strong>tools.google.com/dlpage/gaoptout</strong></a>. You can learn about Mixpanel&rsquo;s practices by going to&nbsp;<a href="https://mixpanel.com/terms/"><strong>mixpanel.com/terms/</strong></a>&nbsp;and opt-out by visiting&nbsp;<a href="https://mixpanel.com/optout"><strong>mixpanel.com/optout</strong></a>.</p>
                <p><strong>Third Party Advertisers.</strong></p>
                <p>We may use third-party advertising companies to serve advertisements regarding goods and services that may be of interest to you when you access and use the Services and other websites, apps or online services, based on information relating to your access to and use of the Services and other websites, apps or online services on any of your devices. To do so, these companies may place or recognize a unique cookie on your browser (including through use of pixel tags). They may also use these technologies, along with information they and we collect about your online use, to recognize you across the devices you use, such as a mobile phone and a laptop. If you would like more information about this practice, and to learn how to opt out of it in desktop and mobile browsers on the particular device on which you are accessing this Privacy Policy, please visit&nbsp;<a href="http://optout.networkadvertising.org/"><strong>optout.networkadvertising.org/</strong></a>&nbsp;and&nbsp;<a href="http://www.aboutads.info/"><strong>www.aboutads.info/</strong></a>. You may also download the AppChoices app at&nbsp;<a href="http://youradchoices.com/appchoices"><strong>youradchoices.com/appchoices</strong></a>&nbsp;to opt out in mobile apps.</p>
                <p><strong>Use and disclosure of the information</strong></p>
                <p>We may use your Personal Information in the following ways:</p>
                <p>to carry out our obligations relating to your contracts and transactions with us and to provide you with the information, products and services that you request from us, as well as related customer service;</p>
                <p>to notify you about changes to our Services or to send you other administrative information;</p>
                <p>as part of our efforts to keep our Services safe and secure;</p>
                <p>to administer our Services and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p>
                <p>to improve our Website and to ensure other Services are presented in the most effective manner;</p>
                <p>to measure or understand the effectiveness of advertising we serve and to deliver relevant advertising to you;</p>
                <p>to allow you to participate in interactive features of our Services, when you choose to do so;</p>
                <p>to facilitate social sharing functionality;</p>
                <p>consistent with choices that may be available to you, to provide you with information about other similar goods and services we offer; and</p>
                <p>to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you.</p>
                <p>We may also use and disclose Personal Information as we believe necessary or appropriate: (a) to comply with any applicable legal and/or regulatory requirements, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence, upon demonstration of lawful authority; (d) to enforce our Customer Agreement with you; (e) as part of our efforts to keep our Services safe and secure, including to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others, and to investigate, prevent, detect or suppress fraud; and (f) to allow us to pursue available remedies or limit the damages that we may sustain.</p>
                <p>We may use and disclose Technical Information for any purpose, except where we are required to do otherwise under applicable law. If we are required to treat Technical Information as Personal Information under applicable law, then we may use it for the purposes for which we use and disclose Personal Information as detailed in this Privacy Policy. If we combine Technical Information with Personal Information, we will treat the combined information as Personal Information as long as it is combined.</p>
                <p>We may disclose and share your Personal Information with selected third parties including:</p>
                <p>affiliates, business partners, joint marketers, suppliers and sub-contractors for the performance of any contract we enter into with them or you;</p>
                <p>advertisers and advertising networks solely to select and serve relevant advertisements to you and others;</p>
                <p>to third party service providers that connect to your bank account(s) with bank account login credentials provided by you in order for us to perform Services and confirm availability of funds; and</p>
                <p>analytics and search engine providers that assist us in the improvement and optimization of our site.</p>
                <p>Your Personal Information may also be disclosed:</p>
                <p>consistent with choices that may be available to you, to our affiliates for the purposes described in this Privacy Policy;</p>
                <p>consistent with choices that may be available to you, to permit selected third parties to provide you with information about goods or services;</p>
                <p>by you, on message boards, chat, profile pages and blogs and other services to which you are able to post information and materials. Please note that any information you post or disclose through these services will become public and may be available to other users and the general public;</p>
                <p>to your friends associated with your social media account, to other users of the Services and to your social media account provider, in connection with your social sharing activity, such as if you connect your social media account to your Services account or log into your Services account from your social media account. By connecting your Services account and your social media account, you authorize us to share information with your social media account provider, and you understand that the use of the information we share will be governed by the social media site&rsquo;s privacy policy;</p>
                <p>if you open or access your Alborz Payment Account directly on a third party website or via a third party application, with the provider of the third party website or application;</p>
                <p>to explore and/or undertake a corporate transaction, including any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings);</p>
                <p>if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our&nbsp;<a href="https://alborzpayment.com/#/terms"><strong>Customer Agreement</strong></a>&nbsp;and other applicable agreements; or to protect the rights, property, or safety of Alborz Payment, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction;</p>
                <p>to assist us in conducting or co-operating in investigations of fraud or other illegal activity, or to respond to requests from public and government authorities (including those outside your country of residence), upon demonstration of lawful authority;</p>
                <p>to prevent and detect fraud or crime;</p>
                <p>in response to a subpoena, warrant, court order, or as otherwise required by law;</p>
                <p>to assess financial and insurance risks, and to protect our operations and those of any of our affiliates;</p>
                <p>to allow us to pursue available remedies or limit the damages that we may sustain;</p>
                <p>to recover debt or in relation to your insolvency; and</p>
                <p>to develop customer relationships, services and systems.</p>
                <p><strong>Your Choices Regarding our Use of your Personal Information</strong></p>
                <p>If you no longer want to receive marketing-related emails from us on a going-forward basis, you may opt-out by following the instructions in any such email. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt-out, we may still send you important administrative messages, from which you cannot opt-out.</p>
                <p><strong>Your Rights &ndash; Access to and Correction of Personal Information</strong></p>
                <p>You have the following rights regarding your Personal Information in our possession or under our control:</p>
                <p>Upon request, be informed of the existence, use and disclosure of your Personal Information and be given access to that information.</p>
                <p>Challenge the accuracy and completeness of your Personal Information and have it amended as appropriate. Depending on the nature of the information, amendment may involve the correction, deletion or addition of information.</p>
                <p>Withdraw your consent to the collection, use or disclosure of your Personal Information.</p>
                <p>You may contact us at&nbsp;<a href="mailto:privacy@alborzpayment.com"><strong>privacy@alborzpayment.com</strong></a>&nbsp;to make a request. We will respond to a request as soon as reasonably practicable. We may need to verify your identity before addressing your request. An access request may be subject to a fee. If the request is unfounded, unlawful or excessive, we may refuse the request.</p>
                <p>Please note that we may need to retain certain information for recordkeeping purposes, to comply with our obligations under applicable laws and regulations, including but not limited to our anti-money laundering obligations, and/or to complete any transactions that you began prior to requesting a change or deletion.</p>
                <p><strong>How do we protect your Personal Information</strong></p>
                <p>Your Personal Information is protected by security safeguards appropriate to the level of sensitivity of the information through (i) physical measures, such as secure areas; (ii) technical measures, such as encryption and secure servers; and (iii) organizational measures, such as due diligence in transferring Personal Information to third-party processors. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account has been compromised), please immediately notify us in accordance with the &ldquo;Contact&rdquo; section below. For more information about security, please see the Account Security and Privacy and Information Security sections of the Customer Agreement.</p>
                <p><strong>Cross-Border Transfer.</strong></p>
                <p>We may transfer your Personal Information to a third-party service provider for processing and storage. Whenever we engage a third-party service provider, we ensure that the information is properly safeguarded at all times at a comparable level of protection the information would have received if it had not been transferred.</p>
                <p>Your Personal Information may be stored and processed in any country where we have facilities or in which we engage service providers, and by using the Services you consent to the transfer of information to countries outside of Canada, including the United States, which may have different data protection rules. In the event that your Personal Information is transferred outside of Canada, the government, courts or law enforcement or regulatory agencies of that jurisdiction may be able to obtain disclosure of your Personal Information through the laws of that jurisdiction.</p>
                <p><strong>Third Party Services</strong></p>
                <p>Our Services may, from time to time, contain links to and from the websites of our partner networks, advertisers, affiliates, and others. The inclusion of a link on the Services does not imply endorsement of the linked site or service by us. If you follow a link to any of these websites, please note that we are not responsible for the privacy, these websites have their own privacy policies and that we do not accept any responsibility for them. Please check these policies before you submit any personal data to these websites.</p>
                <p>In addition, we are not responsible for the information collection, use, disclosure or security policies or practices of other organizations, such as Apple, Google, Microsoft, RIM or any other app developer, app provider, operating system provider, wireless service provider or device manufacturer, including with respect to any Personal Information you disclose to other organizations through or in connection with the Apps.</p>
                <p><strong>Use of Services by Minors</strong></p>
                <p>The Services are not directed to individuals under the age of eighteen (18), and we request that they not provide Personal Information through the Services.</p>
                <p><strong>Limited Liability</strong></p>
                <p>Alborz Payment might have partnership(s) with money exchange store(s) to ease transferring money between the countries. Alborz Payment does not have any liability in case any of those money exchange stores do any illegal acts in their business and hide them from Alborz Payment or the government of Canada. Alborz Payment is founded based on transparency, liability, security, legality, legitimacy, and safety to its customers, the government of Canada, and FINTRAC.</p>
                <p><strong>Changes to our Privacy Policy</strong></p>
                <p>We may change this Privacy Policy. The &ldquo;Last Updated&rdquo; legend at the top of this page indicates when this Privacy Policy was last revised. Any changes we may make to our Privacy Policy will become effective when we post the revised Privacy Policy on the Services. Your use of the Services following these changes means that you accept the revised Privacy Policy.</p>
                <p><strong>Contact</strong></p>
                <p>Questions, comments and requests regarding this Privacy Policy are welcomed and should be sent to:&nbsp;<a href="mailto:privacy@alborzpayment.com"><strong>privacy@alborzpayment.com</strong></a>. Because email communications are not always secure, please do not include credit card or other sensitive information in your emails to us.</p>
              </Col>
            </Row>
          </Container>
        </section>
        <ContactUs />
      </main>
    );
  }
}

export default Privacy;
