/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    UncontrolledCollapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar as StrapNavbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";
import {Translate} from "components/i18n";
import animateScrollTo from 'animated-scroll-to';
import {withRouter} from "react-router-dom";
import Banner from "components/Banner";

import './index.scss';
import {apNamespace} from "../../services/http";

class Navbar extends React.Component {
    componentDidMount() {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
    }

    state = {
        collapseClasses: "",
        collapseOpen: false
    };

    onExiting = () => {
        this.setState({
            collapseClasses: "collapsing-out"
        });
    };

    onExited = () => {
        this.setState({
            collapseClasses: ""
        });
    };

    langChangeHandler = (lang) => () => {
        this.props.onLangChange(lang);
    }

    render() {
        return (
            <>
                <Banner/>
                <header className="header-global">
                    <StrapNavbar
                        className="navbar-main navbar-transparent navbar-light headroom"
                        expand="lg"
                        id="navbar-main"
                    >
                        <Container>
                            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                                <img
                                    alt="..."
                                    src={require("assets/img/logo/logo-w.svg").default}
                                />
                            </NavbarBrand>
                            <Button
                                className="btn-neutral btn-icon d-lg-none"
                                color="default"
                                href="/panel"
                                target="_blank"
                            >
                            <span className="nav-link-inner--text ml-1">
                              <Translate>Login/Register</Translate>
                            </span>
                            </Button>
                            <button className="navbar-toggler" id="navbar_global">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <UncontrolledCollapse
                                toggler="#navbar_global"
                                navbar
                                className={this.state.collapseClasses}
                                onExiting={this.onExiting}
                                onExited={this.onExited}
                            >
                                <div className="navbar-collapse-header">
                                    <Row>
                                        <Col className="collapse-brand" xs="6">
                                            <Link to="/">
                                                <img
                                                    alt="..."
                                                    src={require("assets/img/logo/logo.svg")}
                                                />
                                            </Link>
                                        </Col>
                                        <Col className="collapse-close" xs="6">
                                            <button className="navbar-toggler" id="navbar_global">
                                                <span/>
                                                <span/>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                                    {/* <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Team</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="#"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Getting started
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Learn how to use Argon compiling Scss, change
                              brand colors and more.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href="#"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-palette" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Foundation
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Learn more about colors, typography, icons and the
                              grid system we used for Argon.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href="#"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-ui-04" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Components
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Browse our 50 beautiful handcrafted components
                              offered in the Free version.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle nav>
                                            <i className="ni ni-collection d-lg-none mr-1"/>
                                            <span
                                                className="nav-link-inner--text"><Translate>About Us</Translate></span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Link to="/team">
                                                <DropdownItem>
                                                    <Translate>Team</Translate>
                                                </DropdownItem>
                                            </Link>
                                            <DropdownItem onClick={this.animateHandler('#contact-us-section')}>
                                                <Translate>Contact Us</Translate>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem>
                                        <NavLink
                                            to="/terms"
                                            tag={Link}
                                        >
                                            <i className="ni ni-paper-diploma d-lg-none mr-1"/>
                                            <span className="nav-link-inner--text"><Translate>Terms</Translate></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            to="/privacy"
                                            tag={Link}
                                        >
                                            <i className="ni ni-paper-diploma d-lg-none mr-1"/>
                                            <span className="nav-link-inner--text"><Translate>Privacy</Translate></span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <Nav className="align-items-lg-center ml-lg-auto navbar-nav-hover" navbar>
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle nav>
                                            <i className="ni ni-world-2 mr-1"/>
                                            <span className="nav-link-inner--text"><Translate>Lang</Translate></span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={this.langChangeHandler('en')}>
                                                <Translate>English</Translate>
                                            </DropdownItem>
                                            <DropdownItem onClick={this.langChangeHandler('fa')}>
                                                <Translate>Farsi</Translate>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/alborzpayment"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        <Translate>Instagram</Translate>
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      <Translate>Follow us on Instagram</Translate>
                    </UncontrolledTooltip>
                  </NavItem> */}
                                    <NavItem className="d-none d-lg-block ml-lg-4">
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="default"
                                            href={apNamespace ? `/ns/${apNamespace}/panel` : '/panel'}
                                            target="_blank"
                                        >
                                          <span className="nav-link-inner--text ml-1">
                                            <Translate>Login/Register</Translate>
                                          </span>
                                        </Button>
                                    </NavItem>
                                </Nav>
                            </UncontrolledCollapse>
                        </Container>
                    </StrapNavbar>
                </header>
                <div className="whatsapp-anchor" onClick={this.openWhatsappHandler}>
                    <i className="fa fa-whatsapp"/>
                </div>
            </>
        );
    }

    animateHandler = (id) => () => {
        const el = document.querySelector(id);
        if (el) {
            animateScrollTo(el);
        } else {
            this.props.history.push('');
            setTimeout(() => {
                this.animateHandler(id)();
            }, 500);
        }
    }

    openWhatsappHandler = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let link = `https://api.whatsapp.com/send?phone=16477454666&text=${encodeURIComponent('Hi Alborzpayment')}`;
        if (isMobile) {
            link = link.replace('https://api.whatsapp.com', ' whatsapp:/');
        }
        window.open(link);
    }
}

export default withRouter(Navbar);
