/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import Exchange from "components/Exchange/index.jsx";
import { Translate } from "components/i18n/index.js";
import registerSvg from "assets/svg/08.svg";
import amountSvg from "assets/svg/06.svg";
import recipientSvg from "assets/svg/02.svg";
import verifySvg from "assets/svg/05.svg";
import paySvg from "assets/svg/03.svg";
import finishSvg from "assets/svg/16.svg";
import { ContactUs } from "../../components/ContactUs";

import './style.scss';

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <main ref="main">
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row className="exchange-section">
                  <Col sm="6" className="exchange-section-part">
                    {/* <h1 className="display-3 text-white">
                      <Translate>Alborz Payment</Translate>
                    </h1> */}
                    <p className="display-4 text-white">
                      <Translate>A cheaper, faster, and transparent platform for international money transfer</Translate>
                    </p>
                    <p className="lead text-white">
                      <Translate>All operations are online and available 24/7</Translate>
                    </p>
                    <p className="lead text-white">
                      <Translate>alborz_payment_slugan</Translate>
                    </p>
                    <div className="btn-wrapper mb-3">
                      <Button
                        href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136303231332c227072696d617279536561726368223a7b226f72674e616d65223a22416c626f727a207061796d656e74222c2273656172636854797065223a317d7d/"
                        target="_blank"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-balance-scale" />
                        </span>
                        <span className="btn-inner--text"><Translate>Fintrac Regulated</Translate></span>
                      </Button>
                    </div>
                    {/* <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Components</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Download React
                          </span>
                        </Button>
                      </div>
                     */}
                  </Col>
                  <Col sm="6">
                    <Exchange />
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200 cards-area">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h2 className="display-3 text-white mb-4 text-center"><Translate>How To Send Money</Translate></h2>
              </Col>
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={registerSvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_1_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_1_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={amountSvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_2_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_2_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={recipientSvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_3_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_3_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={verifySvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_4_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_4_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={paySvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_5_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_5_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0 how-to-pay-card">
                      <CardBody className="py-5">
                        <CardImg src={finishSvg} alt="" />
                        <h6 className="text-primary text-uppercase">
                          <Translate>block_6_title</Translate>
                        </h6>
                        <p className="description mt-3">
                          <Translate>block_6_desc</Translate>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section press-section-separator">
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        {/*<section className="section section-lg press-section">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h2 className="display-3 text-white mb-4 text-center"><Translate>Press</Translate></h2>
              </Col>
               <Col lg="6">
                <Card className="card-lift--hover shadow border-0 press-card">
                  <CardBody className="py-5">
                    <h6 className="text-primary text-uppercase">
                      <Translate>press_1_title</Translate>
                    </h6>
                    <p className="description mt-3">
                      <Translate>press_1_body</Translate>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-lift--hover shadow border-0 press-card">
                  <CardBody className="py-5">
                    <h6 className="text-primary text-uppercase">
                      <Translate>press_3_title</Translate>
                    </h6>
                    <p className="description mt-3">
                      <img src={uYorkLogo} alt="york"/>
                      <Translate>press_3_body</Translate>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-lift--hover shadow border-0 press-card">
                  <CardBody className="py-5">
                    <h6 className="text-primary text-uppercase">
                      <Translate>press_2_title</Translate>
                    </h6>
                    <p className="description mt-3">
                      <img src={utIranLogo} alt="utiran"/>
                      <Translate>press_2_body</Translate>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
           SVG separator
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>*/}
        {/*<section className="section section-lg">
          <Carousel />
        </section>*/}
        {/* <section className="section section-lg" id="team-section">
          <Container>
            <Row className="justify-content-center text-center mb-lg">
              <Col lg="8">
                <h2 className="display-3"><Translate>The amazing Team</Translate></h2>
                <p className="lead text-muted">
                  <Translate>The amazing team description</Translate>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="mb-5 mb-lg-0" lg="3" md="6">
                <div className="px-4">
                  <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require("assets/img/theme/team-1-800x800.jpg")}
                    style={{ width: "200px" }}
                  />
                  <div className="pt-4 text-center">
                    <h5 className="title">
                      <span className="d-block mb-1">Ryan Tompson</span>
                      <small className="h6 text-muted">Web Developer</small>
                    </h5>
                    <div className="mt-3">
                      <Button
                        className="btn-icon-only rounded-circle"
                        color="warning"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="warning"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="warning"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-dribbble" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="3" md="6">
                <div className="px-4">
                  <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require("assets/img/theme/team-2-800x800.jpg")}
                    style={{ width: "200px" }}
                  />
                  <div className="pt-4 text-center">
                    <h5 className="title">
                      <span className="d-block mb-1">Romina Hadid</span>
                      <small className="h6 text-muted">
                        Marketing Strategist
                        </small>
                    </h5>
                    <div className="mt-3">
                      <Button
                        className="btn-icon-only rounded-circle"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-dribbble" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="3" md="6">
                <div className="px-4">
                  <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require("assets/img/theme/team-3-800x800.jpg")}
                    style={{ width: "200px" }}
                  />
                  <div className="pt-4 text-center">
                    <h5 className="title">
                      <span className="d-block mb-1">Alexander Smith</span>
                      <small className="h6 text-muted">UI/UX Designer</small>
                    </h5>
                    <div className="mt-3">
                      <Button
                        className="btn-icon-only rounded-circle"
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-dribbble" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 mb-lg-0" lg="3" md="6">
                <div className="px-4">
                  <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require("assets/img/theme/team-4-800x800.jpg")}
                    style={{ width: "200px" }}
                  />
                  <div className="pt-4 text-center">
                    <h5 className="title">
                      <span className="d-block mb-1">John Doe</span>
                      <small className="h6 text-muted">Founder and CEO</small>
                    </h5>
                    <div className="mt-3">
                      <Button
                        className="btn-icon-only rounded-circle"
                        color="success"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="success"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                      <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="success"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-dribbble" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        <ContactUs />
      </main>
    );
  }
}

export default Landing;
