import axios from 'axios';

const prefix = window.__SERVER_URL__ || 'http://localhost:9020';

export const apNamespace = window.__NAMESPACE__ || '';

export const apNamespaceObj = window.__NAMESPACE_OBJ__ || null;

export const C_EMPTY_ID = '000000000000000000000000';

export const getFilePath = (path, ignoreNamespace) => {
    if (path.indexOf('https:') > -1 || path.indexOf('http:') > -1) {
        return path;
    } else {
        if (apNamespace && ignoreNamespace !== true) {
            return `${prefix}/file/${apNamespace}/${path}`;
        } else {
            return `${prefix}/file/${path}`;
        }
    }
};

export default class Http {
    static getInstance() {
        if (!this.instance) {
            this.instance = new Http();
        }

        return this.instance;
    }

    static instance;
    jwt = '';
    user = null;

    constructor() {
        this.jwt = this.getToken();
        this.user = this.getUser();
    }

    postFile(formData, onProgress) {
        const config = this.getFileConfig(onProgress);

        return new Promise((resolve, reject) => {
            axios.post(prefix + '/file/upload', formData, config).then((res) => {
                if (!res.data.status) {
                    reject(res.data.error);
                } else {
                    resolve(res.data.data);
                }
            }).catch(reject);
        });
    }

    post(cmd, param, {jwt}) {
        const config = this.getConfig(jwt);

        return new Promise((resolve, reject) => {
            axios.post(prefix + cmd, param, config).then((res) => {
                if (!res.data.status) {
                    reject(res.data.error);
                } else {
                    resolve(res);
                }
            }).catch(reject);
        });
    }

    put(cmd, param, {jwt}) {
        const config = this.getConfig(jwt);

        return new Promise((resolve, reject) => {
            axios.put(prefix + cmd, param, config).then((res) => {
                if (!res.data.status) {
                    reject(res.data.error);
                } else {
                    resolve(res);
                }
            }).catch(reject);
        });
    }

    get(cmd, {jwt}) {
        const config = this.getConfig(jwt);

        return new Promise((resolve, reject) => {
            axios.get(prefix + cmd, config).then((res) => {
                if (!res.data.status) {
                    reject(res.data.error);
                } else {
                    resolve(res);
                }
            }).catch(reject);
        });
    }

    delete(cmd, {jwt}) {
        const config = this.getConfig(jwt);

        return new Promise((resolve, reject) => {
            axios.delete(prefix + cmd, config).then((res) => {
                if (!res.data.status) {
                    reject(res.data.error);
                } else {
                    resolve(res);
                }
            }).catch(reject);
        });
    }

    storeToken(token) {
        localStorage.setItem('alborzpayment.jwt', token);
        this.jwt = token;
    }

    getToken() {
        if (!this.jwt) {
            const v = localStorage.getItem('alborzpayment.jwt');
            if (v) {
                this.jwt = v;
            }
        }
        return this.jwt;
    }

    storeUser(user) {
        localStorage.setItem('alborzpayment.user', JSON.stringify(user));
        this.user = user;
    }

    getUser() {
        if (!this.user) {
            const v = localStorage.getItem('alborzpayment.user');
            if (v) {
                this.user = JSON.parse(v);
            }
        }
        return this.user;
    }

    clearStore() {
        localStorage.removeItem('alborzpayment.user');
        localStorage.removeItem('alborzpayment.jwt');
        this.jwt = null;
        this.user = null;
    }

    getConfig(jwt) {
        const headers = {};
        if (apNamespace) {
            headers['X-Namespace'] = apNamespace;
        }
        if (this.jwt && jwt !== false) {
            headers['Authorization'] = `Bearer ${this.jwt}`;
        }
        if (Object.keys(headers).length > 0) {
            return {
                headers,
            };
        }
        return null;
    }

    getFileConfig(onProgress) {
        const headers = {};
        if (apNamespace) {
            headers['X-Namespace'] = apNamespace;
        }
        if (this.jwt) {
            headers['Authorization'] = `Bearer ${this.jwt}`;
            headers['Content-Type'] = 'multipart/form-data';
        }
        if (Object.keys(headers).length > 0) {
            return {
                headers: {
                    'Authorization': `Bearer ${this.jwt}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: onProgress,
            };
        } else {
            return {
                onUploadProgress: onProgress,
            };
        }
    }

    getJWTKey() {
        if (apNamespace) {
            return `${apNamespace}.alborzpayment.jwt`;
        }
        return 'alborzpayment.jwt';
    }

    getUserKey() {
        if (apNamespace) {
            return `${apNamespace}.alborzpayment.user`;
        }
        return 'alborzpayment.user';
    }
}

export {prefix};